import {mapGetters, mapActions, mapMutations} from 'vuex';

export default {
  name: 'boxTypes.list',
  data() {
    return {}
  },
  components: {},
  created() {
    this.fetchBoxTypes();
  },
  mounted() {},
  computed: {
    ...mapGetters({
      boxTypes: 'boxTypes/boxTypes',
      isBoxTypesLoading: 'boxTypes/isBoxTypesLoading',
    }),
  },
  watch: {},
  methods: {
    ...mapActions({
      fetchBoxTypes: 'boxTypes/BOX_TYPES_REQUEST_LIST_GET',
      deleteBoxType: 'boxTypes/BOX_TYPES_REQUEST_DELETE',
    }),
    ...mapMutations({
      setBoxType: 'boxTypes/BOX_TYPES_DATA',
    }),
    getPlacement(item){
      let place = item.product.data.consignments.data.length ? item.product.data.consignments.data[0].places.data[0] : null;
      return place ? `${place.shelf.data.rack.data.store.data.title},${place.shelf.data.rack.data.title},<br>${place.shelf.data.title},${place.title}` : '';
    },
    deleteItem(item) {
      this.$set(item, 'isLoading', true);
      this.deleteBoxType(item.id).then(() => {
        this.$toasted.success(this.$t('boxTypesDeleted'));
      }).catch(error => {
        if (error.response.status === 422) {
          this.$toasted.error(error.response.data.message);
        }
      }).finally(()=>{
        this.$set(item, 'isLoading', false);
      });
    },
    edit(item) {
      this.setBoxType(item)
      this.$router.push({name: 'boxTypes.edit', params: {boxTypeId: item.id}}).catch(() => {console.log();});
    },
    show(item) {
      this.$router.push({name: 'products.show', params: {productId: item.product_id}}).catch(() => {console.log();});
    }
  },
  destroyed() {},
}
